/* General button styling */
.infoButton {
    border: 1px solid var(--color-black);
    padding: 10%;
    border-radius: 10px;
    transition-duration: .6s;
    transition-property: background-color;
}

.infoButton:hover {
    background-color: var(--color-clay);
    cursor: pointer;
}

.infoButton:active {
    border: 2px solid var(--color-gray);
}

/* Info button content styling */
.infoButton ul {
    list-style-type: none;
}

.bullet {
    text-align: center;
    padding: 10px 0;
}

.bullet:first-of-type {
    padding-top: 25px;
}

/* Mobile */
@media (max-width: 576px) {
    .infoButton {
        margin: 10px 0;
        padding: 5%;
    }

    .infoButton:last-child {
        margin-bottom: 120px;
    }
}