/* This circular nav is a modified version of the original: https://codepen.io/Kapilnemo/pen/gMgLWr/?editors=1100 */

/* Hides the input checkbox used to toggle nav */
.input {position: absolute; display: none}

/* Boiiler plate that I don't fully understand */
.mobileNav > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Positions the nav */
.mobileNav {
    position: fixed;
    bottom: 15%;
    left: 50%;
    transform: translate(-35px, 0);
    z-index: 1;
}

/* Button styling */
.btn {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--color-black);
}

.btn {
    background: var(--color-red);
    font-size: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-pack: center;
            align-items: center;

    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/* Fades the buttons */
.btn:not(:first-child) {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: -2;
    
    -webkit-transition: all 0.6s cubic-bezier(.87,-.41,.19,1.44);
    transition: all 0.6s cubic-bezier(.87,-.41,.19,1.44);
}

/* Slides the buttons in */
.btn:nth-child(2) {top:0px;left:0px;-webkit-transition-delay: 0s;transition-delay: 0s}
.btn:nth-child(3) {top:0px;left:0px;-webkit-transition-delay: 0.1s;transition-delay: 0.1s}
.btn:nth-child(4) {top:0px;left:0px;-webkit-transition-delay: 0.2s;;transition-delay: 0.2s}
.btn:nth-child(5) {top:0px;left:0px;-webkit-transition-delay: 0.3s;transition-delay: 0.3s}
.btn:nth-child(6) {top:0px;left:0px;-webkit-transition-delay: 0.4s;transition-delay: 0.4s}
.btn:nth-child(7) {top:0px;left:0px;-webkit-transition-delay: 0.5s;transition-delay: 0.5s}

/* Fades the buttons in */
input.input:checked ~ .showMenu .btn:nth-child(2) {
    top:-50px;left:-75px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.input:checked ~ .showMenu .btn:nth-child(3) {
    top:-95px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.input:checked ~ .showMenu .btn:nth-child(4) {
    top:-50px;left:75px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.input:checked ~ .showMenu .btn:nth-child(5) {
    top:-130px;left:-80px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.input:checked ~ .showMenu .btn:nth-child(6) {
    top:-180px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.input:checked ~ .showMenu .btn:nth-child(7) {
    top:-130px;left:80px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/* Centers hamburger and X icon so they are in the same spot */
.menuBtn, .closeBtn {
    position: absolute;
    transition: all 0.3s ease;
}

.closeBtn {
    transform: translateY(50px);
    opacity: 0;
}

/* Switches the hamburger and X icon */
input[type=checkbox].input:checked ~ .showMenu .btn .menuBtn {
    transform: translateY(-50px);
    opacity: 0;
}
  
input[type=checkbox].input:checked ~ .showMenu .btn .closeBtn {
    transform: translateY(0px);
    opacity: 1;
}

/* Styles the text in the nav */
.mobileNavText {
    font-weight: 700;
    font-size: .8rem;
    color: var(--color-black);
}

/* Styles the material icons in the nav */
i {
    color: var(--color-black);
}

/* Tablet */
@media (min-width: 769px) {
    .mobileNav {
        display: none;
    }
}