/* Container styling */
.dataRow {
    margin-bottom: 25px;
}

.dataRow h1 {
    margin-bottom: 10px;
}

.dataRow h3 {
    margin-bottom: 10px;
}

.linkRow {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
}

/* Styling for the tech tags */
.tag {
    background-color: var(--color-red);
    border: 1px solid var(--color-cream);
    color: var(--color-cream);
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    margin: 5px;
    transition-duration: .2s;
}

.tag:first-of-type {
    margin-left: 0;
}

.tag:last-of-type {
    margin-right: 0;
}

.tag:hover {
    border: 1px solid var(--color-gray);
    font-weight: 600;
}

.tag:active {
    border: 2px solid var(--color-black);
}

/* Styling for the related site links */
.link {
    background-color: var(--color-cream);
    border: 1px solid var(--color-gray);
    color: var(--color-gray);
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    margin: 5px;
    transition-duration: .2s;
}

.link:first-of-type {
    margin-left: 0;
}

.link:last-of-type {
    margin-right: 0;
}

.link:hover {
    background-color: var(--color-gray);
    color: var(--color-cream);
    font-weight: 600;
}

.link:active {
    background-color: var(--color-black);
    border-color: var(--color-black);
}

/* Styles the page break */
hr {
    margin-top: 5px;
    width: 200px;
}

/* Makes bottom spacer not show on large screen */
.bottomSpacer {
    display: none;
}

/* Tablet */
@media (max-width: 768px) {
    /* Show bottom spacer on tablets or smaller */
    .bottomSpacer {
        display: block;
        height: 5vw;
        width: auto;
    }
  }

/* Mobile */
@media (max-width: 576px) {
    /* More spacer styling */
    .bottomSpacer {
        height: 150px;
    }

    /* Container styling */
    .dataContainer {
        text-align: center;
    }

    .dataContainer p {
        text-align: justify;
    }

    .linkRow {
        justify-content: center;
    }

    .dataRow {
        margin-bottom: 15px;
    }

    /* Adjusts page break styling for mobile */
    hr {
        width: 150px;
        height: 1px;
        margin: 5px auto 0 auto;
        background-color: var(--color-gray);
        border: none;
    }

    /* Animation */
    .dataContainer {
        -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
}


@media (min-width: 577px) {
    /* Animation */
    /* Changes fade-in direction when screeen is bigger than mobile */
    .dataContainer {
        -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
}

/* Animation */
@-webkit-keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }

@-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }