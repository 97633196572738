/* Container styling */
.messsageContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 8vh 0;
}

.messsageContainer > * {
    margin-bottom: 50px;
}

/* Text styling */
.backMessage {
    text-decoration: none;
    color: var(--color-gray);
    font-size: 1.5rem;
    transition-duration: .2s;
}

.backMessage:hover {
    font-weight: 700;
    color: var(--color-black);
}

/* Laptop */
@media (max-width: 1024px) {
    /* Adjust's styling when screen shrinks */
    .messsageContainer {
        padding-top: 50%;
        text-align: center;
    }

    .backMessage {
        font-size: 1rem;
    }
}