@import url(https://fonts.googleapis.com/css?family=Spartan:100,200,300,400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Animation to play when Nav first loads */
.Nav_desktopNavContainer__RUTEF {
    -webkit-animation: Nav_fade-in-left__1aMdC 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: Nav_fade-in-left__1aMdC 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* Container styling */
nav {
    position: relative;
    display: inline;
}

.Nav_desktopNav__2fXWP {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 25vw;
    height: 100vh;
    padding: 8vh 0;
    position: relative;
    z-index: 100;
}

.Nav_navGroup__33Em- {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SVG button styling */
svg {
    width: 12%;
    fill: var(--color-gray);
    background-color: var(--color-cream);
    border-radius: 15px;
}

svg:first-child {
    margin-right: 5px;
}

svg:last-child {
    margin-left: 5px;
}

svg:hover {
    fill: var(--color-cream);
    background-color: var(--color-gray);
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px rgba(37, 36, 34, .5);
}

svg:active {
    background-color: var(--color-black);
    box-shadow: none;
}

/* Bottom links styling */
.Nav_iconLinks__2X0PB {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--color-cream);
}

.Nav_iconLink__1Jrfu {
    transition-duration: .2s;
}

.Nav_iconLink__1Jrfu:hover {
    box-shadow: none;
    border: 2px solid var(--color-gray);
}

/* Top links styling */
.Nav_mainNavLink__1v2vB {
    color: var(--color-gray);
    background-color: var(--color-cream);
    text-decoration: none;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.2rem;
    transition-duration: .2s;
}

.Nav_mainNavLink__1v2vB:first-child {
    margin-top: 0;
}

.Nav_mainNavLink__1v2vB:last-child {
    margin-bottom: 0;
}

.Nav_mainNavLink__1v2vB:hover {
    color: var(--color-cream);
    background-color: var(--color-gray);
    text-decoration: none;
    font-weight: 600;
    box-shadow: 0px 0px 3px 0px rgba(37, 36, 34, .5);
}

.Nav_mainNavLink__1v2vB:active {
    background-color: var(--color-black);
    box-shadow: none;
}

/* Increases the font weight of the button whose page we are currently on */
.Nav_currentPage__3C9Gm {
    font-weight: 600;
}

/* Styling for the vertical link that "connects" all the buttons */
.Nav_verticalLine__2eS9v {
    width: 2px;
    height: 100vh;
    background: var(--color-clay);
    margin: auto;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
}

/* Animation */
@-webkit-keyframes Nav_fade-in-left__1aMdC {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes Nav_fade-in-left__1aMdC {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

/* Laptop L */
@media (max-width: 1440px) {
    /* SVG Button styling */
    .Nav_iconLink__1Jrfu {
        border-radius: 10px;
        width: 15%;
    }
}

/* Laptop */
@media (max-width: 1024px) {
    /* SVG Button styling */
    .Nav_iconLink__1Jrfu {
        width: 20%;
    }
  }

/* Tablet */
@media (max-width: 768px) {
    /* Hides the desktop navigation and vertical line design on tablet or smaller. */
    .Nav_desktopNav__2fXWP, .Nav_verticalLine__2eS9v {
        display: none;
    }
}
/* This circular nav is a modified version of the original: https://codepen.io/Kapilnemo/pen/gMgLWr/?editors=1100 */

/* Hides the input checkbox used to toggle nav */
.MobileNav_input__2iGo0 {position: absolute; display: none}

/* Boiiler plate that I don't fully understand */
.MobileNav_mobileNav__132Wa > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

/* Positions the nav */
.MobileNav_mobileNav__132Wa {
    position: fixed;
    bottom: 15%;
    left: 50%;
    -webkit-transform: translate(-35px, 0);
            transform: translate(-35px, 0);
    z-index: 1;
}

/* Button styling */
.MobileNav_btn__MKVcf {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--color-black);
}

.MobileNav_btn__MKVcf {
    background: var(--color-red);
    font-size: 15px;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
        -ms-flex-pack: center;
            align-items: center;

    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/* Fades the buttons */
.MobileNav_btn__MKVcf:not(:first-child) {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: -2;
    transition: all 0.6s cubic-bezier(.87,-.41,.19,1.44);
}

/* Slides the buttons in */
.MobileNav_btn__MKVcf:nth-child(2) {top:0px;left:0px;transition-delay: 0s}
.MobileNav_btn__MKVcf:nth-child(3) {top:0px;left:0px;transition-delay: 0.1s}
.MobileNav_btn__MKVcf:nth-child(4) {top:0px;left:0px;;transition-delay: 0.2s}
.MobileNav_btn__MKVcf:nth-child(5) {top:0px;left:0px;transition-delay: 0.3s}
.MobileNav_btn__MKVcf:nth-child(6) {top:0px;left:0px;transition-delay: 0.4s}
.MobileNav_btn__MKVcf:nth-child(7) {top:0px;left:0px;transition-delay: 0.5s}

/* Fades the buttons in */
input.MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf:nth-child(2) {
    top:-50px;left:-75px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf:nth-child(3) {
    top:-95px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf:nth-child(4) {
    top:-50px;left:75px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf:nth-child(5) {
    top:-130px;left:-80px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf:nth-child(6) {
    top:-180px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
  
input.MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf:nth-child(7) {
    top:-130px;left:80px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/* Centers hamburger and X icon so they are in the same spot */
.MobileNav_menuBtn__2FYN8, .MobileNav_closeBtn__HeCrt {
    position: absolute;
    transition: all 0.3s ease;
}

.MobileNav_closeBtn__HeCrt {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
}

/* Switches the hamburger and X icon */
input[type=checkbox].MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf .MobileNav_menuBtn__2FYN8 {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
}
  
input[type=checkbox].MobileNav_input__2iGo0:checked ~ .MobileNav_showMenu__iY5Aw .MobileNav_btn__MKVcf .MobileNav_closeBtn__HeCrt {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
}

/* Styles the text in the nav */
.MobileNav_mobileNavText__1HT8r {
    font-weight: 700;
    font-size: .8rem;
    color: var(--color-black);
}

/* Styles the material icons in the nav */
i {
    color: var(--color-black);
}

/* Tablet */
@media (min-width: 769px) {
    .MobileNav_mobileNav__132Wa {
        display: none;
    }
}
/* General container styling */
.About_container__3bN0l {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 8vh 0;
    height: 100%;
    overflow: hidden;
}

.About_left__xY7et, .About_right__3ZdFJ {
    width: 50%;
    padding: 0 5%;
}

.About_left__xY7et {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.About_right__3ZdFJ {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: auto;
}

/* Content styling */
.About_content__3EmYl > *:not(:first-child) {
    padding: 10px 0;
}

.About_content__3EmYl *:nth-child(2) {
    padding-bottom: 2px;
}

.About_content__3EmYl *:nth-child(3) {
    padding-top: 2px;
}

.About_content__3EmYl p {
    text-align: justify;
    line-height: 1.8;
}

/* Laptop L */
@media (max-width: 1440px) {
    /* Spacing adjustments */
    .About_left__xY7et, .About_right__3ZdFJ {
        padding: 0 4%;
    }
}

/* Mobile */
@media (max-width: 576px) {
    /* Spacing adjustments */
    .About_container__3bN0l {
        flex-direction: column;
        padding: 5vh 0 0 0;
    }
    
    .About_left__xY7et, .About_right__3ZdFJ {
        width: 100%;
        padding: 0 6%;
    }

    .About_content__3EmYl p {
        margin: 10px 0;
    }
}
/* General button styling */
.InfoCard_infoButton__1xq_b {
    border: 1px solid var(--color-black);
    padding: 10%;
    border-radius: 10px;
    transition-duration: .6s;
    transition-property: background-color;
}

.InfoCard_infoButton__1xq_b:hover {
    background-color: var(--color-clay);
    cursor: pointer;
}

.InfoCard_infoButton__1xq_b:active {
    border: 2px solid var(--color-gray);
}

/* Info button content styling */
.InfoCard_infoButton__1xq_b ul {
    list-style-type: none;
}

.InfoCard_bullet__wrKFj {
    text-align: center;
    padding: 10px 0;
}

.InfoCard_bullet__wrKFj:first-of-type {
    padding-top: 25px;
}

/* Mobile */
@media (max-width: 576px) {
    .InfoCard_infoButton__1xq_b {
        margin: 10px 0;
        padding: 5%;
    }

    .InfoCard_infoButton__1xq_b:last-child {
        margin-bottom: 120px;
    }
}
/* Container styling */
.Work_container__25sOx {
    display: flex;
    justify-content: space-between;
    padding: 8vh 0;
    height: 100%;
    overflow: hidden;
}

.Work_left__1Gp-f, .Work_right__k13sc {
    padding: 0 5%;
}

.Work_left__1Gp-f {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 35%;
}

/* Custom styling for the the 'Projects' title */
.Work_workTitle__29fYy {
    font-size: 3rem;
    margin-bottom: 15px;
    color: var(--color-red);
}

/* Styling for the right column */
.Work_right__k13sc {
    width: 65%;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.Work_getStarted__JPD5K {
    margin-top: 100px;
}

/* Button styling */
.Work_projectButton__35Sg2 {
    border: 1px solid var(--color-black);
    padding: 5%;
    margin-bottom: 20px;
    border-radius: 10px;
    transition-duration: .6s;
    transition-property: background-color;
}

.Work_projectButton__35Sg2 h3 {
    margin-bottom: 10px;
    font-size: 1rem;
}

.Work_projectButton__35Sg2 p {
    font-size: .9rem;
}

.Work_projectButton__35Sg2:hover {
    background-color: var(--color-clay);
    cursor: pointer;
}

.Work_projectButton__35Sg2:active {
    border: 2px solid var(--color-gray);
}

.Work_projectButton__35Sg2:last-of-type {
    margin-bottom: 0;
}

/* Vertical page break styling */
.Work_verticalLine__1xF_c {
    width: 2px;
    height: 100%;
    background: var(--color-clay);
    margin: auto;
    top: 0;
    left: 50%;
    z-index: -1;
}

/* Laptop */
@media (max-width: 1024px) {
    .Work_workTitle__29fYy {
        font-size: 2rem;
    }
  }

/* Tablet */
@media (max-width: 768px) {
    .Work_workTitle__29fYy {
        font-size: 1.6rem;
    }

    .Work_projectButton__35Sg2 h3 {
        font-size: .9rem;
    }

    .Work_projectButton__35Sg2 p {
        font-size: .8rem;
    }
  }

/* Mobile */
@media (max-width: 576px) {
    .Work_container__25sOx {
        flex-direction: column;
        padding: 5vh 0 0 0;
    }
    
    .Work_left__1Gp-f, .Work_right__k13sc {
        width: 100%;
        padding: 0 5%;
    }

    .Work_right__k13sc {
        padding-top: 40px;
        text-align: center;
    }

    .Work_verticalLine__1xF_c {
        display: none;
    }
}
/* Container styling */
.ProjectInfo_dataRow__MFFgO {
    margin-bottom: 25px;
}

.ProjectInfo_dataRow__MFFgO h1 {
    margin-bottom: 10px;
}

.ProjectInfo_dataRow__MFFgO h3 {
    margin-bottom: 10px;
}

.ProjectInfo_linkRow__3e4Si {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
}

/* Styling for the tech tags */
.ProjectInfo_tag__2BZIf {
    background-color: var(--color-red);
    border: 1px solid var(--color-cream);
    color: var(--color-cream);
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    margin: 5px;
    transition-duration: .2s;
}

.ProjectInfo_tag__2BZIf:first-of-type {
    margin-left: 0;
}

.ProjectInfo_tag__2BZIf:last-of-type {
    margin-right: 0;
}

.ProjectInfo_tag__2BZIf:hover {
    border: 1px solid var(--color-gray);
    font-weight: 600;
}

.ProjectInfo_tag__2BZIf:active {
    border: 2px solid var(--color-black);
}

/* Styling for the related site links */
.ProjectInfo_link__12O2j {
    background-color: var(--color-cream);
    border: 1px solid var(--color-gray);
    color: var(--color-gray);
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    margin: 5px;
    transition-duration: .2s;
}

.ProjectInfo_link__12O2j:first-of-type {
    margin-left: 0;
}

.ProjectInfo_link__12O2j:last-of-type {
    margin-right: 0;
}

.ProjectInfo_link__12O2j:hover {
    background-color: var(--color-gray);
    color: var(--color-cream);
    font-weight: 600;
}

.ProjectInfo_link__12O2j:active {
    background-color: var(--color-black);
    border-color: var(--color-black);
}

/* Styles the page break */
hr {
    margin-top: 5px;
    width: 200px;
}

/* Makes bottom spacer not show on large screen */
.ProjectInfo_bottomSpacer__3pXjG {
    display: none;
}

/* Tablet */
@media (max-width: 768px) {
    /* Show bottom spacer on tablets or smaller */
    .ProjectInfo_bottomSpacer__3pXjG {
        display: block;
        height: 5vw;
        width: auto;
    }
  }

/* Mobile */
@media (max-width: 576px) {
    /* More spacer styling */
    .ProjectInfo_bottomSpacer__3pXjG {
        height: 150px;
    }

    /* Container styling */
    .ProjectInfo_dataContainer__2cN6E {
        text-align: center;
    }

    .ProjectInfo_dataContainer__2cN6E p {
        text-align: justify;
    }

    .ProjectInfo_linkRow__3e4Si {
        justify-content: center;
    }

    .ProjectInfo_dataRow__MFFgO {
        margin-bottom: 15px;
    }

    /* Adjusts page break styling for mobile */
    hr {
        width: 150px;
        height: 1px;
        margin: 5px auto 0 auto;
        background-color: var(--color-gray);
        border: none;
    }

    /* Animation */
    .ProjectInfo_dataContainer__2cN6E {
        -webkit-animation: ProjectInfo_fade-in-fwd__321UO 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: ProjectInfo_fade-in-fwd__321UO 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
}


@media (min-width: 577px) {
    /* Animation */
    /* Changes fade-in direction when screeen is bigger than mobile */
    .ProjectInfo_dataContainer__2cN6E {
        -webkit-animation: ProjectInfo_fade-in-left__1Qv9u 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: ProjectInfo_fade-in-left__1Qv9u 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
}

/* Animation */
@-webkit-keyframes ProjectInfo_fade-in-fwd__321UO {
    0% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes ProjectInfo_fade-in-fwd__321UO {
    0% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }

@-webkit-keyframes ProjectInfo_fade-in-left__1Qv9u {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes ProjectInfo_fade-in-left__1Qv9u {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
/* Container styling */
.Contact_container__47MkX {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 8vh 0;
    height: 100%;
    overflow: hidden;
}

.Contact_left__2Cisa {
    padding: 0 5%;
}

/* Page-specific styling */
.Contact_left__2Cisa h1 {
    margin-bottom: 10px;
}

/* Form styling */
.Contact_input__2iK9c:not(:last-child) {
    display: block;
    padding: 1vh;
    margin-bottom: 20px;;
}

input[type=text] {
    width: 400px;
    background-color: var(--color-cream);
    border-color: var(--color-clay);
    border-style: solid;
    border-radius: 4px;
}

input[type=text]:active {
    border-color: var(--color-gray);
}

textarea {
    width: 400px;
    resize: none;
    min-height: 40px;
    max-height: 40vh;
    background-color: var(--color-cream);
    border-color: var(--color-clay);
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;
}

input[type=submit] {
    border-radius: 10px;
    color: var(--color-cream);
    background-color: var(--color-red);
    border: none;
    padding: 15px;
    font-size: 1.2rem;
    margin-left: 300px;
    transition-duration: .2s;
}

input[type=submit]:hover {
    cursor: pointer;
    color: var(--color-cream);
    background-color: var(--color-gray);
    border-color: var(--color-gray);
}

input[type=submit]:active {
    background-color: var(--color-black);
}

.Contact_label__28S0l {
    color: var(--color-gray);
}

/* Form submitted message styling */
.Contact_thankYouMessage__2KJdI {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20vh;
}

.Contact_backMessage__2wiA5 {
    text-decoration: none;
    color: var(--color-gray);
    padding-top: 20px;
    transition-duration: .2s;
}

.Contact_backMessage__2wiA5:hover {
    font-weight: 700;
    color: var(--color-black);
}

.Contact_hidden__1j3ll {
    display: none;
}

/* Tablet */
@media (max-width: 768px) {
    /* Centers and spaces form on tablets or smaller */
    .Contact_container__47MkX {
        flex-direction: column;
        padding: 5vh 0 0 0;
        align-items: center;
    }

    .Contact_contactTitle__3r0uK {
        text-align: center;
    }

    .Contact_thankYouMessage__2KJdI {
        text-align: center;
    }
}

/* Mobile */
@media (max-width: 576px) {
    /* More form styling but on mobile or smaller */
    .Contact_left__2Cisa, .Contact_right__aGR5r {
        width: 100%;
        padding: 0 5%;
    }

    input[type=text] {
        width: 100%;
    }

    textarea {
        width: 100%;
    }

    input[type=submit] {
        margin: auto;
        float: right;
        height: 50px;
    }
}
/* Container styling */
.PageNotFound_messsageContainer__23YqT {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 8vh 0;
}

.PageNotFound_messsageContainer__23YqT > * {
    margin-bottom: 50px;
}

/* Text styling */
.PageNotFound_backMessage__30ZYF {
    text-decoration: none;
    color: var(--color-gray);
    font-size: 1.5rem;
    transition-duration: .2s;
}

.PageNotFound_backMessage__30ZYF:hover {
    font-weight: 700;
    color: var(--color-black);
}

/* Laptop */
@media (max-width: 1024px) {
    /* Adjust's styling when screen shrinks */
    .PageNotFound_messsageContainer__23YqT {
        padding-top: 50%;
        text-align: center;
    }

    .PageNotFound_backMessage__30ZYF {
        font-size: 1rem;
    }
}
/* Import all weights of our font */

/* Our color palette */
:root {
  --color-cream: #fffcf2;
  --color-clay: #e6ddd2;
  --color-gray: #403d39;
  --color-black: #252422;
  --color-red: #eb5e28;
}

/* Set's page color */
html {
  background-color: #fffcf2;
  background-color: var(--color-cream);
}

/* Boiler plate styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: top;
  font-family: Spartan, Arial, Helvetica, sans-serif;
  /* outline: 1px solid pink; */
}

/* Basic column layout */
.App {
  display: flex;
  justify-content: space-between;
}

/* Sets size of the page minus the Nav and applies fade in/out animation */
main {
 width: 75vw;
 overflow: hidden;
}

main * {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* Font and header sizes when on Desktop */
h1 {
  font-size: 4rem;
  font-weight: 900;
  color: #eb5e28;
  color: var(--color-red);
}

h2, h3 {
  font-size: 1.4rem;
  color: #403d39;
  color: var(--color-gray);
}

h2 {
  font-weight: 800;
}

h3 {
  font-weight: 600;
}

p {
  color: #403d39;
  color: var(--color-gray);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5;
}

/* Laptop L */
@media (max-width: 1440px) {
  /* Font and header sizes */
  h1 {
    font-size: 3.3rem;
  }

  h2, h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1.1rem;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  /* Font and header sizes */
  h1 {
    font-size: 2.5rem;
  }

  h2, h3 {
    font-size: 1.2rem;
  }
}

/* Tablet */
@media (max-width: 768px) {
  /* Sets width too 100% of viewport width when Nav disappears and MobileNav appears */
  main {
    width: 100vw;
  }

  /* Font and header sizes */
  h1 {
    font-size: 2.3rem;
  }
  
  h2, h3 {
    font-size: 1rem;
  }
  
  p {
    font-size: 1rem;
  }
}

/* Mobile */
/* @media (max-width: 576px) {

} */

/* Animation */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

