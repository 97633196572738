/* Animation to play when Nav first loads */
.desktopNavContainer {
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* Container styling */
nav {
    position: relative;
    display: inline;
}

.desktopNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 25vw;
    height: 100vh;
    padding: 8vh 0;
    position: relative;
    z-index: 100;
}

.navGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SVG button styling */
svg {
    width: 12%;
    fill: var(--color-gray);
    background-color: var(--color-cream);
    border-radius: 15px;
}

svg:first-child {
    margin-right: 5px;
}

svg:last-child {
    margin-left: 5px;
}

svg:hover {
    fill: var(--color-cream);
    background-color: var(--color-gray);
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px rgba(37, 36, 34, .5);
}

svg:active {
    background-color: var(--color-black);
    box-shadow: none;
}

/* Bottom links styling */
.iconLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--color-cream);
}

.iconLink {
    transition-duration: .2s;
}

.iconLink:hover {
    box-shadow: none;
    border: 2px solid var(--color-gray);
}

/* Top links styling */
.mainNavLink {
    color: var(--color-gray);
    background-color: var(--color-cream);
    text-decoration: none;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.2rem;
    transition-duration: .2s;
}

.mainNavLink:first-child {
    margin-top: 0;
}

.mainNavLink:last-child {
    margin-bottom: 0;
}

.mainNavLink:hover {
    color: var(--color-cream);
    background-color: var(--color-gray);
    text-decoration: none;
    font-weight: 600;
    box-shadow: 0px 0px 3px 0px rgba(37, 36, 34, .5);
}

.mainNavLink:active {
    background-color: var(--color-black);
    box-shadow: none;
}

/* Increases the font weight of the button whose page we are currently on */
.currentPage {
    font-weight: 600;
}

/* Styling for the vertical link that "connects" all the buttons */
.verticalLine {
    width: 2px;
    height: 100vh;
    background: var(--color-clay);
    margin: auto;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
}

/* Animation */
@-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

/* Laptop L */
@media (max-width: 1440px) {
    /* SVG Button styling */
    .iconLink {
        border-radius: 10px;
        width: 15%;
    }
}

/* Laptop */
@media (max-width: 1024px) {
    /* SVG Button styling */
    .iconLink {
        width: 20%;
    }
  }

/* Tablet */
@media (max-width: 768px) {
    /* Hides the desktop navigation and vertical line design on tablet or smaller. */
    .desktopNav, .verticalLine {
        display: none;
    }
}