/* Container styling */
.container {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 8vh 0;
    height: 100%;
    overflow: hidden;
}

.left {
    padding: 0 5%;
}

/* Page-specific styling */
.left h1 {
    margin-bottom: 10px;
}

/* Form styling */
.input:not(:last-child) {
    display: block;
    padding: 1vh;
    margin-bottom: 20px;;
}

input[type=text] {
    width: 400px;
    background-color: var(--color-cream);
    border-color: var(--color-clay);
    border-style: solid;
    border-radius: 4px;
}

input[type=text]:active {
    border-color: var(--color-gray);
}

textarea {
    width: 400px;
    resize: none;
    min-height: 40px;
    max-height: 40vh;
    background-color: var(--color-cream);
    border-color: var(--color-clay);
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;
}

input[type=submit] {
    border-radius: 10px;
    color: var(--color-cream);
    background-color: var(--color-red);
    border: none;
    padding: 15px;
    font-size: 1.2rem;
    margin-left: 300px;
    transition-duration: .2s;
}

input[type=submit]:hover {
    cursor: pointer;
    color: var(--color-cream);
    background-color: var(--color-gray);
    border-color: var(--color-gray);
}

input[type=submit]:active {
    background-color: var(--color-black);
}

.label {
    color: var(--color-gray);
}

/* Form submitted message styling */
.thankYouMessage {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20vh;
}

.backMessage {
    text-decoration: none;
    color: var(--color-gray);
    padding-top: 20px;
    transition-duration: .2s;
}

.backMessage:hover {
    font-weight: 700;
    color: var(--color-black);
}

.hidden {
    display: none;
}

/* Tablet */
@media (max-width: 768px) {
    /* Centers and spaces form on tablets or smaller */
    .container {
        flex-direction: column;
        padding: 5vh 0 0 0;
        align-items: center;
    }

    .contactTitle {
        text-align: center;
    }

    .thankYouMessage {
        text-align: center;
    }
}

/* Mobile */
@media (max-width: 576px) {
    /* More form styling but on mobile or smaller */
    .left, .right {
        width: 100%;
        padding: 0 5%;
    }

    input[type=text] {
        width: 100%;
    }

    textarea {
        width: 100%;
    }

    input[type=submit] {
        margin: auto;
        float: right;
        height: 50px;
    }
}