/* Import all weights of our font */
@import url('https://fonts.googleapis.com/css?family=Spartan:100,200,300,400,500,600,700,800,900');

/* Our color palette */
:root {
  --color-cream: #fffcf2;
  --color-clay: #e6ddd2;
  --color-gray: #403d39;
  --color-black: #252422;
  --color-red: #eb5e28;
}

/* Set's page color */
html {
  background-color: var(--color-cream);
}

/* Boiler plate styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: top;
  font-family: Spartan, Arial, Helvetica, sans-serif;
  /* outline: 1px solid pink; */
}

/* Basic column layout */
.App {
  display: flex;
  justify-content: space-between;
}

/* Sets size of the page minus the Nav and applies fade in/out animation */
main {
 width: 75vw;
 overflow: hidden;
}

main * {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* Font and header sizes when on Desktop */
h1 {
  font-size: 4rem;
  font-weight: 900;
  color: var(--color-red);
}

h2, h3 {
  font-size: 1.4rem;
  color: var(--color-gray);
}

h2 {
  font-weight: 800;
}

h3 {
  font-weight: 600;
}

p {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5;
}

/* Laptop L */
@media (max-width: 1440px) {
  /* Font and header sizes */
  h1 {
    font-size: 3.3rem;
  }

  h2, h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1.1rem;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  /* Font and header sizes */
  h1 {
    font-size: 2.5rem;
  }

  h2, h3 {
    font-size: 1.2rem;
  }
}

/* Tablet */
@media (max-width: 768px) {
  /* Sets width too 100% of viewport width when Nav disappears and MobileNav appears */
  main {
    width: 100vw;
  }

  /* Font and header sizes */
  h1 {
    font-size: 2.3rem;
  }
  
  h2, h3 {
    font-size: 1rem;
  }
  
  p {
    font-size: 1rem;
  }
}

/* Mobile */
/* @media (max-width: 576px) {

} */

/* Animation */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
