/* Container styling */
.container {
    display: flex;
    justify-content: space-between;
    padding: 8vh 0;
    height: 100%;
    overflow: hidden;
}

.left, .right {
    padding: 0 5%;
}

.left {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 35%;
}

/* Custom styling for the the 'Projects' title */
.workTitle {
    font-size: 3rem;
    margin-bottom: 15px;
    color: var(--color-red);
}

/* Styling for the right column */
.right {
    width: 65%;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.getStarted {
    margin-top: 100px;
}

/* Button styling */
.projectButton {
    border: 1px solid var(--color-black);
    padding: 5%;
    margin-bottom: 20px;
    border-radius: 10px;
    transition-duration: .6s;
    transition-property: background-color;
}

.projectButton h3 {
    margin-bottom: 10px;
    font-size: 1rem;
}

.projectButton p {
    font-size: .9rem;
}

.projectButton:hover {
    background-color: var(--color-clay);
    cursor: pointer;
}

.projectButton:active {
    border: 2px solid var(--color-gray);
}

.projectButton:last-of-type {
    margin-bottom: 0;
}

/* Vertical page break styling */
.verticalLine {
    width: 2px;
    height: 100%;
    background: var(--color-clay);
    margin: auto;
    top: 0;
    left: 50%;
    z-index: -1;
}

/* Laptop */
@media (max-width: 1024px) {
    .workTitle {
        font-size: 2rem;
    }
  }

/* Tablet */
@media (max-width: 768px) {
    .workTitle {
        font-size: 1.6rem;
    }

    .projectButton h3 {
        font-size: .9rem;
    }

    .projectButton p {
        font-size: .8rem;
    }
  }

/* Mobile */
@media (max-width: 576px) {
    .container {
        flex-direction: column;
        padding: 5vh 0 0 0;
    }
    
    .left, .right {
        width: 100%;
        padding: 0 5%;
    }

    .right {
        padding-top: 40px;
        text-align: center;
    }

    .verticalLine {
        display: none;
    }
}