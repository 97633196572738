/* General container styling */
.container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 8vh 0;
    height: 100%;
    overflow: hidden;
}

.left, .right {
    width: 50%;
    padding: 0 5%;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: auto;
}

/* Content styling */
.content > *:not(:first-child) {
    padding: 10px 0;
}

.content *:nth-child(2) {
    padding-bottom: 2px;
}

.content *:nth-child(3) {
    padding-top: 2px;
}

.content p {
    text-align: justify;
    line-height: 1.8;
}

/* Laptop L */
@media (max-width: 1440px) {
    /* Spacing adjustments */
    .left, .right {
        padding: 0 4%;
    }
}

/* Mobile */
@media (max-width: 576px) {
    /* Spacing adjustments */
    .container {
        flex-direction: column;
        padding: 5vh 0 0 0;
    }
    
    .left, .right {
        width: 100%;
        padding: 0 6%;
    }

    .content p {
        margin: 10px 0;
    }
}